.error_base {
  @include flex-col-center;
  width: 100%;
  height: 100vh;
  overflow: auto;

  section {
    width: clamp(200px, 80%, 600px);
  }

  h1 {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
  }

  details {
    max-height: 600px;
    overflow: auto;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.1);
    ror summary {
      cursor: pointer;
    }
  }
}

.error_app {
  @extend .error_base;
  background-image: linear-gradient(160deg, $secondary, $primary);
  color: white;
  overflow: auto;
}

.error_component {
  @extend .error_base;
  background-color: $warning_hue;
  color: $warning;
}

.error_message {
  @extend .error_base;
  background-color: $warning_hue;
  color: $primary_dark;
}

.error_button {
  @include flex-row-jfs-acenter;
  padding: 0.5rem;
  margin: 1rem 0rem;
  border: none;
  border-radius: $border_radius_medium;
  color: $warning;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px rgb(150, 150, 150);

  transition: background 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.message_button {
  @extend .error_button;
  color: $primary_dark;
}
