form {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include flex-col;
}

.gradient_background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  @include flex-row-center;

  background-image: linear-gradient(160deg, $secondary, $primary);
}

.jambr_logo {
  align-self: center;
  text-align: center;
  margin: 2rem 0 0;
  img {
    height: auto;
    width: 70%;
  }
}

.custom_form {
  @include flex-col-center;
  height: auto;
  width: clamp(300px, 80%, 600px);
  padding: 0 40px;
  border-radius: 20px;
  position: relative;
  background: white;
  box-shadow: 0px 0px 30px rgb(40, 40, 40);

  form {
    width: 65%;
  }

  @media (max-width: $MAX_WIDTH_FOR_OVERLAPPING_NAV) {
    padding: 0;
  }
}

.form_field_container {
  position: relative;
}

.form_field {
  width: auto;
  min-width: $field_width;
  padding-top: 1.9rem;

  font-size: 1.4rem;
  line-height: 1.7rem;
  position: relative;
  overflow-x: hidden;

  input[type='radio'] {
    border: 1px solid $primary;
  }
}

.form_input {
  width: 100%;
  border: none;
  border-bottom: 2px solid rgba(128, 128, 128, 0.4);
  outline: none;
  font-size: $input_font_size;
  margin-left: 2px;
  position: relative;
}

.form_radio_container {
  @include flex-row-jfs-acenter;
  flex-wrap: wrap;
}

.form_radio {
  position: relative;
  width: 60px;
  height: 1.4rem;
  margin-right: 8px;
  margin-bottom: 8px;

  border-radius: $form_radio_border_radius;

  background-color: rgba(128, 128, 128, 0.2);

  transition: 0.3s ease;

  .form_radio_input {
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    outline: none;

    &:hover + .form_radio_label {
      background-color: rgba(128, 128, 128, 0.9);
      color: white;
    }

    &:checked + .form_radio_label {
      background-color: $primary;
      color: white;
    }
  }

  .form_radio_label {
    position: absolute;
    left: 0px;
    bottom: 0px;

    width: 100%;
    height: 100%;

    border-radius: $form_radio_border_radius;

    // center text
    @include flex-col-center;

    font-size: 1rem;
    line-height: 1rem;

    pointer-events: none;
    transition: 0.3s ease;
  }
}

.form_label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    left: 0px;
    height: 100%;
    width: 101%;
    border-bottom: 2px solid $primary;
    transform: translateX(-101%);
    transition: 0.5s ease;
  }
  .label_content {
    position: absolute;
    left: 4px;
    bottom: 1px;
    font-size: 1rem;
    opacity: 0.5;
    transition: 0.5s ease;
  }
}

.form_input:focus + .form_label::after,
.form_input:valid + .form_label::after {
  transform: translateX(0%);
}

.form_input:focus + .form_label .label_content,
.form_input:valid + .form_label .label_content {
  transform: translateY(-85%);
  font-size: 1rem;
  color: $primary;
  opacity: 1;
}

.form_input_title {
  margin-left: 4px;
  margin-top: 1.9rem;
  font-size: 1rem;
}

.error {
  margin-top: 0.3rem;
  margin-left: 4px;
  margin-bottom: none;
  color: #d8000c;
  width: $field_width;

  &::before {
    display: inline;
    margin-bottom: none;
  }
}

.warning {
  margin-top: 0.3rem;
  margin-left: 4px;
  margin-bottom: none;
  color: $primary_light;
  width: $field_width;

  &::before {
    display: inline;
    margin-bottom: none;
  }
}

.submit_button {
  align-self: center;
  width: auto;
  height: $submit_button_height;
  margin: 20px 0;
  padding: 0 15px;
  border: none;
  border-radius: calc(#{$submit_button_height} / 2);
  outline: none;

  background-color: $primary;
  line-height: 1.1rem;
  font-family: inherit;
  font-weight: light;
  color: rgba(256, 256, 256, 1);
  letter-spacing: 1px;
  text-align: center;

  transition: background 0.2s ease;
  &:hover {
    background: $primary_dark;
    cursor: pointer;
  }
}

.password_info {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateX(-200%) translateY(50%);

  span {
    font-size: 18px;
  }

  .password_tooltip {
    visibility: hidden;
    width: 220px;
    padding: 1rem;
    border-radius: 20px;

    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;

    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(-105%, -50%);
    z-index: 1;
    @include flex-col-center;

    ul {
      margin: 5px 0px 0px;
      padding-left: 10px;
    }
  }

  &:hover .password_tooltip {
    visibility: visible;
  }
}

.error_card {
  width: 100%;
  height: auto;
  padding: 4.5rem 1rem 1rem;
  border-radius: 20px;

  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  z-index: -1;

  background-color: #ffd2d2;
  color: #d8000c;
  transition: 0.4s ease;
}

.show_error {
  transform: translateY(calc(100% - 3rem));
  box-shadow: 0px 0px 15px rgb(40, 40, 40);
}

.account_links {
  margin-bottom: 20px;
  line-height: 1.5rem;
  text-align: center;
}

.adjacent_fields {
  display: flex;
  justify-content: space-between;
  .form_field_container {
    width: 48%;
  }
}

.delete_button {
  position: absolute;
  bottom: 9px;
  right: 5px;
  visibility: hidden;
  color: transparent;
  transition: all 0.2s;
  font-size: 18px;

  &:hover {
    cursor: pointer;
    color: $warning !important;
  }
}

.chip {
  background-color: #dddedf;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.4rem 0.3rem 0 0;

  .chip_delete {
    @include flex-row-center;
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    transition: 0.3s ease;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}

.add_button_wrapper {
  @include flex-col-center;
  width: 2.3rem;
  height: 2.3rem;
  .add_button {
    @include flex-col-center;

    width: 1.9rem;
    height: 1.9rem;
    border-radius: 50%;
    font-size: 1.5rem;
    line-height: 1.5rem;
    background-color: $primary;
    color: white;
    transition: 0.2s ease all;
  }

  .add_button:hover {
    font-size: 2.1rem;
    line-height: 2.1rem;
    cursor: pointer;
  }
}

.contact_us_result {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  color: black;
  margin: 1.4rem 0 1.5rem;
  div {
    @include flex-row-center;
    span {
      margin-left: 0.5rem;
    }
  }
}
