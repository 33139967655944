.editor_container {
  @include flex-col-jfs-acenter;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3rem 4rem;
  background-color: $light_grey;
  z-index: -2;
  isolation: isolate;
  direction: ltr;
  width: 100%;
  height: 100vh;
}

#editor_wrapper {
  position: relative;
  z-index: -1;
  box-shadow: 0px 0px 20px rgb(150, 150, 150);
  width: clamp(360px, 100%, 840px);
  direction: ltr;
}

#editor {
  width: 100%;
  padding: 5rem;
  background-color: white;
}

.fullscreen_editor {
  padding: 0;
  background-color: white;

  #editor_wrapper {
    min-height: 100vh;
  }

  #editor {
    min-height: 100vh;
    padding: 4rem;
    box-shadow: none;
  }
}

@media (max-width: $MAX_WIDTH_FOR_OVERLAPPING_NAV) {
  .editor_container {
    margin-left: 0;
  }
}

.placeholder_element {
  color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  cursor: default;
  user-select: none;
  margin-bottom: 20px;
}

.list_header {
  @include flex-row-jfs-acenter;
  margin-bottom: 1rem;
  width: auto;

  h2 {
    margin: 0;
  }

  .spin_loader {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 1rem;
  }
}

.list_add_wrapper {
  @include flex-col-center;
  position: relative;
  height: 2.2rem;
  width: 2.2rem;
  margin-left: 1rem;
}

.list_add {
  border-radius: 50%;
  color: white;
  background-color: $primary;
  box-shadow: 0px 0px 5px rgb(150, 150, 150);

  transition: border-radius 0.1s, background-color 0.1s;

  span {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: $primary_dark;
    border-radius: 8px;
  }

  .tooltip_popup {
    left: 120%;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Roboto', sans-serif;

    &::after {
      // little arrow pointing at field
      content: '';
      position: absolute;
      top: 50%;
      left: auto;
      right: 100%;
      transform: translate(5%, -50%);
      border-top: 0.3rem solid transparent;
      border-bottom: 0.3rem solid transparent;
      border-right: 0.3rem solid rgb(75, 75, 75);
    }
  }
}

.agenda_add {
  margin-left: 0;

  span {
    font-size: 1.7rem;
    line-height: 1.7rem;
    margin-left: 0.3rem;
  }
}

.unordered_list {
  margin: 0;
  padding-left: 1.5rem;
}

.ordered_list {
  margin: 0;
  padding-left: 1.5rem;
}

#sign_up_prompt {
  @include flex-row-center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 105%);
  border-radius: $border_radius_medium $border_radius_medium 0 0;
  border: 4px solid white;
  border-bottom: none;
  background-image: linear-gradient(160deg, $secondary, $primary);

  box-shadow: 0 0 8px 5px rgba(80, 80, 80, 0.3);
  color: white;
  padding: 1.6rem 2.2rem;
  font-size: larger;

  button {
    @include transition-standard;
    border: 3px solid white;
    background-color: $tertiary;
    height: $submit_button_height;
    border-radius: calc(#{$submit_button_height} / 2);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;

    &:hover {
      border-radius: 4px;
    }
  }
}

.sign_up_prompt_slide_up {
  animation: slide_up 0.3s forwards 30s 1;
}

.sign_up_prompt_dismiss {
  @include transition-standard;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  font-weight: 900;
  border-radius: 50%;
  transform: translate(0.6rem, 0.6rem);
  cursor: pointer;

  &:hover {
    background-color: white;
    color: $secondary;
  }
}

.sign_up_prompt_arrow {
  margin: 0 1rem 0 0.5rem;
}

@keyframes slide_up {
  0% {
    transform: translate(-50%, 105%);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
