.search_section {
  @include flex-col-jfs-acenter;
  position: absolute;
  top: 80px;
  width: 100%;
  height: 150px;
  margin-bottom: 100px;
}

.search_input {
  position: relative;
  width: 20rem;
  height: 40px;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: calc((#{$submit_button_height} + 4px) / 2);
  background-color: white;
  overflow: hidden;
  span {
    position: absolute;
    font-size: $icon_size_large;
    top: 53%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 1;
  }
  input {
    position: absolute;
    padding-left: calc(#{$icon_size_large} + 15px);
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
  }
}

.search_results_container {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 12px;
  width: 100%;
}

.search_no_results {
  margin-top: 25px;
  padding-left: 1rem;
}
