// Icon sizes
$icon_size_huge: 2rem;
$icon_size_large: 1.5rem;
$icon_size_normal: 1rem;
$icon_size_small: 0.8rem;

// JMR styling variables
$jmr_content_margin: 5px;
$jmr_section_margin: 20px;

// Borders
$border_radius_small: 5px;
$border_radius_medium: 10px;

// Cards
$card_padding_sides: 10px;

// Forms
$field_width: 100%;
$field_height: 2.4rem;
$input_font_size: 1.1rem;
$form_radio_border_radius: 6px;
$submit_button_height: 35px;

// Nav Bar
$MAX_WIDTH_FOR_OVERLAPPING_NAV: 680px;

// Utility classes
.hidden {
  display: none;
}

.block {
  display: block;
}

.invisible {
  visibility: hidden;
  width: 0;
  height: 0;
}

.remove_transform {
  transform: none !important;
}

// Cursor styles
.noclick {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.cursor_default {
  cursor: default;
}

.transition_standard {
  transition: 0.2s ease all;
}

.opacity_full {
  opacity: 1 !important;
}

.no_select {
  user-select: none;
}

// Our custom loading wheel
.spin_loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid $primary;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 20px auto;
  align-self: center;
  overflow: hidden;
  animation: spin 1.3s linear infinite;
}

.spin_loader_middle_fixed {
  width: 35px !important;
  height: 35px !important;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top: 5px solid $primary;
  }
  30% {
    transform: rotate(45deg);
    border-top: 5px solid $primary;
  }
  50% {
    transform: rotate(180deg);
    border-top: 5px solid $tertiary;
  }
  70% {
    transform: rotate(315deg);
    border-top: 5px solid $primary;
  }
  100% {
    transform: rotate(360deg);
    border-top: 5px solid $primary;
  }
}

.page_loader {
  position: fixed;
  width: 200vw;
  height: 200vh;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, $secondary_light, $primary_light);
  animation: diagonal_slide 3s linear infinite;
}

@keyframes diagonal_slide {
  0% {
    top: 0;
    left: 0;
  }
  5% {
    top: -2vh;
    left: -2vw;
  }
  50% {
    top: -100vh;
    left: -100vw;
  }
  95% {
    top: -2vh;
    left: -2vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}

// Universal placeholder css
.placeholder_base {
  position: absolute;
  color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

// Position the placeholder text for the agenda item title
.placeholder_title {
  top: 5px;
  left: 40px;
}

// Position the placeholder text for the agenda item notes
.placeholder_notes {
  bottom: 30px;
}

.placeholder_field {
  top: 0px;
}

.no_shadow {
  box-shadow: none !important;
}

// This ensures dropdown options are readable on non Mac OSs
option {
  color: black;
}
