#jmr_title {
  @include transition-standard;
  position: relative;
  margin-bottom: $jmr_section_margin;
  font-size: 4rem;
  font-weight: 300;
  line-height: 4rem;
  width: auto;
}

.jmr_field {
  position: relative;
  margin: $jmr_content_margin 0;
}

.jmr_header {
  margin-top: $jmr_section_margin;
  font-size: 1.5rem;
  font-weight: 100;
}

.jmr {
  @include flex-col-jfs-afs;
  flex: 1;
  padding: 0 40px;
  transition: 0.4s ease;
}

.jmr_placeholder {
  @include flex-row-center;
  height: 100%;
  width: 100%;
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: center;
}
