@import "colors";

.button-as-link {
  background: none;
  color: $primary;
  text-decoration: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;

  &:hover {
    color: $primary_dark;
  }
}
