$preview_list_padding: 10px;

.preview_list {
  @include flex-col-jfs-acenter;
  @include transition-standard;
  position: relative;
  flex: 1;
  width: 100%;
  padding: 0 $preview_list_padding;
  padding-bottom: calc(2 * #{$preview_list_padding});
  transition: 0.3s ease;
  background-color: $medium_grey;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: $darker_grey $dark_grey;
  z-index: 1;
}

.preview_loader_container {
  @include flex-col-center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.preview {
  @include flex-col;
  @include transition-long;
  width: 100%;
  min-width: 250px;
  margin: 4px 0;
  padding: 5px 20px 6px;
  border-radius: 10px;
  background-color: $light_grey;
  color: #000;
  box-shadow: 0px 0px 5px rgb(150, 150, 150);

  &:hover {
    cursor: pointer;
    color: black;
    background-color: $light_grey;
    box-shadow: 0px 0px 10px rgb(50, 50, 50);
  }

  h3 {
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: 5px;
    overflow-x: hidden;
  }

  p {
    overflow-x: hidden;
  }
}

.preview_selected {
  position: relative;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(50, 50, 50);

  color: black;

  &:after {
    content: '';

    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    height: 85%;
    width: 4px;

    background-color: $primary;
    border-radius: 10px;
  }

  &:hover {
    background-color: white;
  }
}

.preview_header {
  @include flex-row-jsb-acenter;
  position: sticky;
  top: -1px;
  width: calc(100% + 2 * #{$preview_list_padding});
  height: 55px;
  background-color: $medium_grey;
  color: black;
  font-size: 2rem;
  line-height: 2.5rem;
  padding: $preview_list_padding;
  margin-bottom: 5px;
  z-index: 1;

  span {
    @include transition-standard;
    font-size: 24px;
    padding: 5px;
    border-radius: 50%;

    &:hover {
      box-shadow: 0px 0px 5px rgb(100, 100, 100);
      cursor: pointer;
    }
  }
}
