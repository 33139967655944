$nav_bar_width: 315px;

#nav_bar {
  @include flex-col-jfs-acenter;
  @include transition-standard;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: $nav_bar_width;
  box-shadow: 0px 0px 15px rgb(100, 100, 100);
  z-index: 2;

  .nav_loader {
    position: absolute;
    margin: 0;
    top: 22.5px;
    right: 22.5px;
    width: 35px;
    height: 35px;
  }
}

.nav_grey_wall {
  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -2;
    pointer-events: none;
    background-color: rgba(200, 200, 200, 0.4);
  }
}

.nav_elements {
  position: relative;
  overflow: visible;
  height: 70px;
  width: 100%;
  background-color: $medium_grey;
  z-index: 10;
}

.nav_button {
  @include flex-col-center;
  position: absolute;
  top: 20px;
  width: 40px;
  height: 40px;
  background-color: $primary;
  color: white;
  border-radius: 50%;
  font-size: 1.7rem;
  box-shadow: 0px 0px 5px rgb(100, 100, 100);
  cursor: pointer;
  user-select: none;

  z-index: 1000;

  &:hover {
    color: white;
    border-radius: 10px;
    background-color: $primary_dark;
  }

  a {
    color: white;
  }

  transition: 0.2s all ease;
}

.nav_button_disabled {
  background-color: $darker_grey;
  box-shadow: 0px 0px 5px rgb(10, 10, 10);
  color: black;

  &:hover {
    color: black;
    border-radius: 10px;
    background-color: $darker_grey;
  }
}

.nav_icon {
  position: absolute;
  transform: translate(-50%, -50%);

  height: 25px;
  width: 25px;
}

.nav_button_active {
  color: white;
  border-radius: 10px;
  background-color: $primary_dark;
}

.nav_account_button {
  left: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;

  // Override default tooltip styles
  .tooltip_popup {
    @include flex-col-jfs-afs;
    left: 0;
    transform: translateX(0);
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    .tooltip_name {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
    &::after {
      content: '';
      top: 0;
      left: 0;
      transform: translate(100%, -90%);
    }
  }

  &:hover {
    .nav_initial {
      display: none;
    }

    .nav_settings {
      display: block;
    }
  }
}

.signout_modal {
  @extend .tooltip_popup;
  visibility: visible !important;
  z-index: 1000 !important;
}

.signout_button_wrapper {
  margin-top: 0.5rem;
  display: flex;
}

.signout_button {
  border: none;
  outline: none;
  color: white !important;
  margin: 0.2rem !important;
}

.nav_jmr_button {
  right: 20px;
  // Override default tooltip styles
  .tooltip_popup {
    @include flex-col-jfs-afs;
    right: 0;
    left: auto;
    transform: translateX(0);
    &::after {
      content: '';
      top: 0;
      right: 0;
      left: auto;
      transform: translate(-100%, -90%);
    }
  }

  .nav_icon {
    top: 48%;
    left: 52%;
  }
}

.nav_action_button {
  right: 70px;

  .nav_icon {
    top: 50%;
    left: 50%;
  }
}

.nav_action_button_active {
  border-radius: 10px;
  background-color: $primary_dark;
}

.nav_feedback_button {
  right: 120px;

  .nav_icon {
    top: 50%;
    left: 50%;
  }
}

.nav_button_text {
  @include flex-row-jfe-acenter;
  @include transition-long;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
  background-color: white;
  box-shadow: 0px 0px 3px rgb(150, 150, 150);
  border-radius: 15px;
  color: black;

  &:hover {
    cursor: pointer;
    border-radius: 5px;
    background-color: $light_grey;
    color: black;
  }
}

.show_hide_nav {
  @include transition-standard;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(50%, -50%);
  width: 30px;
  height: 40px;
  background-color: $primary;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 5px rgb(100, 100, 100);
  z-index: -1;
  user-select: none;

  &:hover {
    cursor: pointer;
    transform: translate(70%, -50%);
  }

  &:hover span {
    transform: translateY(-50%) rotate(180deg);
  }

  span {
    @include flex-col-center;
    @include transition-long;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 50%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: white;
  }
}

@media (max-width: $MAX_WIDTH_FOR_OVERLAPPING_NAV) {
  #nav_bar {
    left: -315px;
  }
}
