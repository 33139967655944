#transcript_wrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  z-index: -1;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(150, 150, 150);
}

.transcript_bound_top {
  top: -2px !important;
  right: 0 !important;
  z-index: 1 !important;

  .transcript_controls {
    position: fixed;
  }

  .transcript_header {
    margin-bottom: 1rem;
  }
}

#transcript {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  box-shadow: inset 10px 0 10px -7px rgb(200, 200, 200);
}

#transcript_toggle {
  @include flex-row-center;
  @include transition-standard;
  user-select: none;
  position: absolute;
  background-color: $primary;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 5px rgb(100, 100, 100);
  cursor: pointer;
  span {
    color: white;
    font-size: $icon_size_large;
  }

  .mic_show_hide {
    @include flex-row-center;
    @include transition-long;
    width: calc(#{$icon_size_normal} * 0.2);
    font-size: $icon_size_normal;
  }

  &:hover .mic_show_hide {
    transform: rotate(180deg);
  }
}

.toggle_behind {
  padding: 0.4rem 0 0.4rem 1.7rem;
  transform: translate(70%, 200%);
  z-index: -3;
  right: 0;
  top: 0;
  &:hover {
    transform: translate(90%, 200%);
  }
}

.toggle_infront {
  padding: 0.4rem 1rem 0.4rem 0.5rem;
  transform: translate(30%, 200%);
  z-index: 1;
  right: 0;
  top: 0;
  &:hover {
    transform: translate(10%, 200%);
  }
}

.toggle_underneath {
  padding: 1rem 0.2rem 0.2rem 0.5rem;
  right: auto;
  left: 50%;
  top: auto;
  bottom: -2px;
  z-index: -1;
  transform: translate(-50%, 70%);

  &.toggle_underneath_hidden {
    z-index: 1;
    transform: translate(-50%, 3px);
    padding-top: 0.5rem;
    bottom: 0;

    &:hover {
      cursor: pointer;
      transform: translate(-50%, 3px);
      padding-bottom: 0.7rem;
    }
  }

  .mic_show_hide {
    transform: rotate(90deg);
    width: $icon_size_normal * 0.4 !important;
  }

  &:hover {
    cursor: pointer;
    transform: translate(-50%, 90%);
  }

  &:hover .mic_show_hide {
    transform: rotate(270deg) !important;
  }
}

.transcript_controls {
  @include flex-row-center;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0 0 0 1.5rem;
  padding: 0.5rem;
  box-shadow: -2px 2px 5px 1px rgba(100, 100, 100, 0.3);
  z-index: 1;
  background: white;
  .user_avatar {
    background-color: $primary;
    text-shadow: none;
    cursor: pointer;
    span {
      font-size: 18px;
    }
    margin-left: 0.5rem;
    padding: 0;

    .tooltip_container {
      cursor: pointer;
      font-family: 'Roboto', sans-serif;
    }
  }
  .spin_loader {
    width: $icon_size_huge;
    height: $icon_size_huge;
    margin: 0 0.3rem 0 0.5rem !important;
  }
}

.transcript_header {
  margin-bottom: 2.6rem;
  font-size: 1.5rem;
  font-weight: 100;
}

.transcript_status {
  font-size: 12px;
  margin-left: 0.5rem;
  color: grey;
}

#utterance_container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 0.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column-reverse;

  &>div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.utterance {
  @include transition-standard;
  position: relative;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 0 0.5rem;
  // margin-top: 1rem;
  .tooltip_container {
    cursor: default;
  }
  .garbage_can {
    @include transition-standard;
    visibility: hidden;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: $icon_size_large;
    opacity: 0.3;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  &:hover {
    border: 2px solid rgba(128, 128, 128, 0.2);
  }

  &:hover .garbage_can {
    visibility: visible;
  }
}

.utterance_placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $dark_grey;
  font-size: 20px;
}

.utterance_keyword {
  span {
    background-color: rgba(256, 0, 0, 0.1);
  }
  .material-icons {
    background-color: transparent;
  }
}

.action_noop_modal {
  width: 250px;
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: small;
  top: 150% !important;
  right: 20% !important;
  border-top-right-radius: 0 !important;
}

.bot_issue_modal {
  width: 230px;
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: small;
  top: 100% !important;
  right: 33% !important;
  border-top-right-radius: 0 !important;
}
