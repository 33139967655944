.tooltip_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: default;
  background-color: transparent;
  transition-delay: 0.5s;
  cursor: pointer;

  &:hover .tooltip_popup {
    // allows the tooltip to immediately dissapear when the mouse leaves,
    // but appear after a small delay when the mouse enters
    visibility: visible;
    transition-delay: 0.5s;
  }

  .preview {
    transition: none !important;
  }
}

.tooltip_popup {
  @include flex-col-jfs-afs;
  visibility: hidden;
  position: absolute;
  top: 120%;
  left: 50%;
  max-width: 300px;
  z-index: 1;
  transform: translateX(-50%);
  font-size: 0.8rem;
  line-height: 0.8rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  background-color: rgb(75, 75, 75);
  color: #fff;
  white-space: nowrap;
  transition-delay: 0s;
  user-select: text;
  cursor: default;

  &::before {
    // allows the user to place their cursor inside the tooltip
    content: '';
    position: absolute;
    top: -7px;
    left: 0px;
    right: 0px;
    height: 9px;
  }

  &::after {
    // little arrow pointing at field
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -90%);
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.2rem solid rgb(75, 75, 75);
  }
}

.show_instantly {
  transition-delay: 0s !important;
  &:hover .tooltip_popup {
    // allows the tooltip to immediately dissapear when the mouse leaves,
    // but appear after a small delay when the mouse enters
    transition-delay: 0s !important;
  }
}

.offset_left {
  left: 0;
  transform: translateX(-75%);
  &::after {
    left: 80%;
  }
}
