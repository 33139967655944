@import './colors';
@import './mixins';
@import './util';
@import './agenda';
@import './jmr';
@import './action_items';
@import './transcript';
@import './jmr_preview_bar';
@import './forms';
@import './nav_bar';
@import './collab_editor';
@import './search';
@import './meta';
@import './meta_modal';
@import './tooltip';
@import './toolbar';
@import './awareness';
@import './jmr_options';
@import './error';
@import './dashboard';
@import './checkout';
@import './button_as_link';

html,
body,
#root,
.App {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

.app_container {
  @include flex-row;
}

.app_content {
  @include transition-standard;
  position: relative;
  margin-left: 315px;
  height: 100vh;
  background-color: $light_grey;
}
