.agenda {
  margin-top: $jmr_section_margin;
}

.agenda_item {
  border: 2px solid transparent;
  border-radius: 6px;
  padding: 0 25px 10px 10px;
  margin-bottom: 10px;
  position: relative;
  background: white;
  transition: border 0.2s ease;

  .sequence_number {
    @include flex-col-jse-acenter;
    position: absolute;
    left: 10px;
    top: 5px;
    width: 20px;
    height: 20px;
    line-height: 1.5rem;
    border-radius: 50%;
    font-weight: bolder;

    &:hover {
      border-radius: 8px;
    }

    &:hover .drag_handle {
      visibility: visible;
    }

    .drag_handle {
      visibility: hidden;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: inherit;
      border-radius: 8px;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: $icon_size_normal;
      }
    }

    .tooltip_container {
      cursor: move;
      cursor: grab;
    }

    .tooltip_popup {
      font-weight: 400;
    }
  }

  .sequence_number_inactive {
    background-color: $dark_grey;
    color: black;

    &:hover {
      background-color: $darker_grey;
    }
  }

  .sequence_number_active {
    background-color: $primary;
    color: white;
    &:hover {
      background-color: $primary_dark;
    }
  }

  &:hover {
    border: 2px solid rgba(128, 128, 128, 0.2);
  }

  &:hover > .delete_button {
    visibility: visible;
    color: rgba(128, 128, 128, 0.4);
  }
}

.agenda_title {
  margin-left: 30px !important;
  margin-bottom: 10px !important;
}
