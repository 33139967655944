.action_item {
  @include transition-long;
  position: relative;
  margin-bottom: 10px;
  border: 2px solid transparent;
  border-left: 4px solid transparent;
  border-radius: 5px;
  padding: 0 20px 0 50px;
  background-color: $light_grey;
  transition: 0.2s ease all;
  &:hover {
    border-top: 2px solid $dark_grey;
    border-bottom: 2px solid $dark_grey;
  }
  &:hover > .delete_button {
    visibility: visible;
    color: rgba(128, 128, 128, 0.4);
  }
}

.action_selected {
  border-left: 4px solid $primary;
}

.action_list {
  margin-top: 20px;
}

.action_button_base {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 10%;
  transition: 0.2s all;
}

.action_button_disabled {
  background-color: $light_grey;
  border: 3px solid $dark_grey;
}

.action_button_active {
  background-color: $light_grey;
  border: 3px solid $primary;
}

.action_button_hover {
  &:hover {
    border: 3px solid $primary_dark;
  }
  &:hover,
  .action_button_complete {
    &:after {
      border-bottom: 3px solid $primary_dark;
      border-right: 3px solid $primary_dark;
    }
  }
}

.action_button_complete {
  &:after {
    content: '';
    position: absolute;
    left: 30%;
    bottom: 20%;
    transform: rotate(45deg);
    width: 40%;
    height: 70%;
    border-bottom: 3px solid $primary;
    border-right: 3px solid $primary;
    transition: 0.2s ease all;
  }
}

.action_list_completed {
  @include flex-row-jfs-acenter;
  margin: 1.85rem 0 1rem;
}

.action_list_showhide {
  @include flex-col-center;
  @include transition-standard;
  position: relative;
  height: 1.2rem;
  width: 2rem;
  background-color: $primary;
  color: white;
  border-radius: 10px;
  margin-left: 1rem;
  box-shadow: 0px 0px 5px rgb(150, 150, 150);
  cursor: pointer;

  span {
    @include transition-standard;
    font-size: 20px;
  }

  &:hover {
    background-color: $primary_dark;
    border-radius: 5px;
  }

  &:hover span {
    transform: rotate(180deg);
  }

  .tooltip_popup {
    font-weight: 100;
  }
}

.action_list_overdue {
  color: $warning;
}

.action_item_different {
  position: absolute;
  top: 0.5rem;
  left: -1.5rem;
  width: 0.8rem;
  height: 0.8rem;
  background-color: $primary_dark;
  border: 0.15rem solid white;
  border-radius: 50%;
}

.action_item_patching {
  border-top: 0.2rem solid $primary_light;
  border-right: 0.2rem solid $primary_light;
  animation: even_spin 1s linear infinite;
}

@keyframes even_spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.action_item_patched {
  position: absolute;
  top: 0.5rem;
  left: -1.5rem;
  width: 0.5rem;
  height: 0.5rem;
  color: $primary_dark;
  span {
    font-size: 1rem;
    font-weight: 900;
  }

  animation: saved 1s forwards;
}

@keyframes saved {
  100% {
    color: transparent;
  }
}
