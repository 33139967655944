#toolbar {
  @include flex-col;
  position: fixed;
  top: 50vh;
  right: 0;
  transform: translate(50%, -50%);
  padding: 3px;
  z-index: 1;
  background-color: $dark_grey;
  border-radius: 10px;
}

.toolbar_button {
  @include flex-col;
  @include transition-standard;
  background-color: white;
  margin: 4px;
  padding: 3px;
  border-radius: 50%;
  &:hover {
    background-color: $light_grey;
  }
}

.toolbar_button_locked {
  color: $medium_grey;
  &:hover {
    background-color: white;
  }
}

.toolbar_button_active {
  color: black;
}

.toolbar_button_inactive {
  color: $darker_grey;
}

.toolbar_icon {
  font-size: 16px;
  vertical-align: text-bottom;
}

@media (max-width: $MAX_WIDTH_FOR_OVERLAPPING_NAV) {
  #toolbar {
    position: fixed;
  }
}
