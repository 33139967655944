.meta_modal {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  cursor: default;

  border: none;
  border-radius: $border_radius_medium;
  font-size: 0.9rem;
  background-color: rgb(50, 50, 50);
  box-shadow: 0px 0px 15px rgb(40, 40, 40);

  // Custom entries must have z-index 2 so they appear above tooltips
  // which have z-index of 1.
  z-index: 2;
}

.meta_input {
  @include flex-col-jse-acenter;

  .error,
  .warning {
    // position the validation error message correctly
    padding-left: calc(1rem + 6px);
    margin: 0;
    margin-top: 6px;
  }
  .error {
    color: red;
  }
  .warning {
    color: $primary_light;
  }
}

// TODO: make styling for text input fields reusable across app
.meta_field {
  @include flex-col-jfs-afs;
  position: relative;
  overflow-x: hidden;
  height: $field_height;
  width: 12rem;
  margin: 1rem 1rem 0;

  input {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;
    padding-left: 0.4rem;
    overflow-y: visible;
    outline: none;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;

    &:focus + label::after,
    &:valid + label::after {
      transform: translateX(0%);
    }

    &:focus + label span,
    &:valid + label span {
      transform: translateY(-85%);
      font-size: 0.8rem;
      color: $primary;
      opacity: 1;
    }
  }

  label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow-y: visible;

    &::after {
      content: '';
      position: absolute;
      left: 0px;
      height: 100%;
      width: 101%;
      border-bottom: 2px solid $primary;
      transform: translateX(-101%);
      transition: 0.2s ease;
    }

    span {
      position: absolute;
      left: 4px;
      bottom: 1px;
      font-size: 0.9rem;
      transition: 0.2s ease;
    }
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

#meta_participants {
  @include flex-col;
  padding: 0.5rem 1rem 1rem 0;

  .participant_row {
    @include flex-row;
    margin-top: 1rem;

    // override meta_field margin
    .meta_field {
      margin: 0;
      margin-right: calc(1rem + 8px);
      margin-left: 1rem;
      width: 15rem;
    }

    .participant_email {
      min-width: 15rem;
      margin: 0 calc(1rem + 4px);

      input {
        width: 100%;
      }
    }

    .spin_loader_container {
      @include flex-col-center;
      width: 4rem;
      margin: 0 1rem 0 2rem;
      .spin_loader {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }
}

.control_button_base {
  @include flex-col-jfs-acenter;
  width: 4rem;
  margin: 0 1rem 0 2rem;
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
}

.control_button_remove {
  background-color: white;
  color: black;
  &:hover {
    background-color: $warning;
    color: white;
  }
}

.control_button_confirm {
  background-color: $primary;
  color: white;
  &:hover {
    background-color: $primary_dark;
  }
}

.participant_permission_selector {
  @include flex-col-jfe-acenter;
  width: 5rem;
}

.permission_selector {
  background: transparent;
  color: white;
  outline: none;
  border: none;
  border-bottom: 2px solid $primary;
  &::after {
    content: '';
    background-color: $primary;
  }
  &:hover {
    border-bottom: 2px solid $primary_dark;
  }
}

.new_participant {
  // position the validation error message correctly
  .error {
    padding-left: calc(1rem + 6px);
    margin: 0;
    margin-top: 6px;
    color: red;
  }
  .permission_selector {
    position: relative;

    select {
      position: absolute;
      bottom: 0px;
    }
  }
  .new_participant_button {
    @include flex-col-jfe-acenter;
    height: $field_height;
  }
}

.meta_text_button_container {
  @include flex-row-jsa-acenter;
  margin-top: 1rem;

  .meta_button {
    @include transition-standard;
    min-width: 3rem;
    margin: 0 0.3rem 1rem;
    padding: 0.2rem 0.4rem;
    border-radius: $border_radius_small;
    cursor: pointer;
  }

  .cancel_button_colors {
    background-color: white;
    color: black;

    &:hover {
      background-color: rgba(256, 256, 256, 0.8);
    }
  }

  .confirm_button_colors {
    background-color: $primary;
    color: white;

    &:hover {
      background-color: $primary_dark;
    }
  }

  .delete_button_colors {
    background-color: white;
    color: black;
    &:hover {
      background-color: $warning;
      color: white;
    }
  }
}

.meta_location {
  @include flex-col-center;
  padding-top: 1rem;
  a {
    color: $primary;
    &:hover {
      color: $primary_dark;
    }
  }
}
