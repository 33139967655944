.dashboard_container {
  height: 100vh;
  padding: 4rem 3.5rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: white;

  .meta_container {
    margin-top: 0;
  }
}

.dash_action_list {
  width: clamp(10rem, 100%, 40rem);
}

.dash_action_title {
  width: 100%;
  resize: none;
  overflow: hidden;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  outline: none;
}

.dash_action_placeholder {
  top: 11px;
  left: 52px;
}
