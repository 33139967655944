#jmr_options {
  @include flex-row-center;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0 0 0 1.5rem;
  padding: 0.5rem;
  box-shadow: -2px 2px 5px 1px rgba(100, 100, 100, 0.3);
  z-index: 1;
  background: white;
  .garbage_can {
    @include transition-standard;
    font-size: $icon_size_large;
    opacity: 0.3;
    margin: 0 0.2rem;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.is_external_icon {
  @include flex-row-center;
  position: relative;
  cursor: default !important;
  .tooltip_container {
    cursor: default;
  }
}

.jmr_modal {
  position: absolute;
  top: 120%;
  right: 10%;
  border: none;
  border-radius: $border_radius_medium;
  background-color: rgb(75, 75, 75);
  box-shadow: 0px 0px 15px rgb(120, 120, 120);
  z-index: 1;
  color: white;
  span {
    opacity: 1;
  }
}

.jmr_delete_modal {
  @include flex-col-jfs-acenter;
  border-top-right-radius: 0;
  text-align: center;
  width: 200px;
  padding: 1rem 1rem 0;
  span {
    font-size: 1rem;
  }
  .spin_loader {
    margin: 0 0.5rem 1rem !important;
    width: 25px;
    height: 25px;
    margin: 0;
  }
}

.jmr_share_modal {
  @include flex-col-jfs-afs;
  // white-space: break-spaces;
  width: 320px;
  padding: 1rem 1rem;
  .meta_text_button_container {
    align-self: center;
    width: 70%;
    margin-top: 0;
  }
  .spin_loader {
    width: 20px;
    height: 20px;
    margin: 0;
  }
}

.jmr_share_title {
  font-size: 1.2rem;
  font-weight: 100;
  margin-bottom: 1rem;
}

.jmr_share_dialogue {
  @include flex-row-jsa-acenter;
  width: 100%;
  font-weight: 100;
  select {
    margin-left: 5px;
  }
}

.jmr_modal_pointer {
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-left: 0.8rem solid transparent;
  border-bottom: 0.4rem solid rgb(75, 75, 75);
}

.jmr_share_modal_pointer {
  right: 1.8rem;
  transform: translate(0, -98%);
  border-right: 0.8rem solid transparent;
}

.jmr_delete_modal_pointer {
  right: 0;
  transform: translate(0, -98%);
  border-right: 0.4rem solid transparent;
}

.control_button_share {
  margin: 0 0 0 2rem;
  font-weight: 400;
  font-size: 0.9rem;
}

.share_link {
  @include flex-row-center;
  margin-top: 1rem;
  width: 100%;
}

.share_link_input {
  width: 72%;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  font-size: smaller;
  font-weight: 100;
}

.share_link_copy_container {
  position: relative;
  margin-left: 0.4rem;
}

.share_link_copy {
  @include transition-standard;
  font-size: $icon_size_large;
  border-radius: 50%;
  padding: 0.4rem;
  cursor: pointer;
  &:hover {
    background-color: rgb(100, 100, 100);
  }
}

#share_link_copy_alert {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 0.5rem;

  border-radius: $border_radius_small;
  background-color: rgb(125, 125, 125);

  font-weight: 100;
  font-size: small;
  text-align: center;
  width: 7rem;

  visibility: hidden;
}

.share_link_copy_animation {
  animation: fade_out 2s linear;
}

@keyframes fade_out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
