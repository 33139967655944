.meta_container {
  @include flex-row;
  flex-wrap: wrap;
  margin: $jmr_content_margin 0;
}

.meta_base {
  @include flex-row-center;
  width: auto;
  max-width: 100%;
  position: relative;
  border-radius: $border_radius_small;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}

.meta_data {
  overflow: hidden;
  text-overflow: ellipsis;
}

.meta_large {
  height: 1.8rem;
  margin: 0.3rem 0.6rem 0.3rem 0;
  padding: 0px 0.4rem;
  span {
    font-size: $icon_size_normal;
  }
}

.meta_small {
  height: 1.4rem;
  font-size: 0.8rem;
  margin: 0.2rem 0.4rem 0.2rem 0;
  padding: 0 0.4rem;
  span {
    font-size: $icon_size_small;
  }
}

.meta_hover:hover {
  cursor: pointer;
  background-color: $primary_dark;
}

.meta_colors_inactive {
  background-color: $dark_grey;
  color: black;

  // Prevents color change on hover when meta chip is inactive
  &:hover,
  .meta_hover:hover {
    background-color: $dark_grey;
  }
}

.meta_colors_active {
  background-color: $primary;
  color: $light_grey;
}

.meta_darkened {
  background-color: $primary_dark;
}

.meta_data {
  margin-left: 3px;
}
