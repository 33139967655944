.user_avatar {
  @include flex-col-center;
  @include transition-standard;
  position: relative;
  height: $icon_size_huge;
  width: $icon_size_huge;
  border: 2px solid white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-shadow: 1px 1px 1px rgb(50, 50, 50);
  //shift text 1 px to the left to account for shadow
  padding-right: 1px;
  border-radius: 50%;
  color: white;
  margin: 0 0.3rem;
  box-shadow: 0px 0px 3px rgb(150, 150, 150);
  font-size: 1rem;
  line-height: 1rem;

  &:hover {
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgb(150, 150, 150);
  }

  .tooltip_container {
    cursor: default;
    &:hover .tooltip_popup {
      transition-delay: 0s !important;
    }
  }

  .tooltip_popup {
    top: 130%;
    left: auto;
    transform: none;
    right: -50%;
    opacity: 1;
    padding: 0.5rem;
    text-shadow: none;

    &::after {
      left: auto;
      right: 13%;
      transform: translateY(-90%);
    }
    h5 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }
    span {
      opacity: 1;
      font-size: 0.8rem;
      transition: 0s all !important;
    }
  }

  hr {
    width: 100%;
    margin: 0.6rem 0;
  }
}

.extra_users_avatar {
  background-color: $primary;
  font-size: 11px;
  font-family: 'Roboto', sans-serif;

  .tooltip_popup {
    padding-right: 0;
    width: auto;
  }

  .extra_users_tooltip {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .extra_users_entry {
    @include flex-row-center;
    margin-right: 0.5rem;
  }

  .extra_users_color_wrapper {
    height: 2rem;
    width: 1rem;
    margin-right: 0.5rem;
    background-color: white;
    border-radius: 0.5rem;
  }

  .extra_users_color {
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 0.5rem;
  }

  .extra_users_data {
    flex: 1;
  }
}

.anon_user {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  text-align: center;
  padding-right: 1px;
}

.caret {
  position: absolute;
  pointer-events: none;
  user-select: none;
  height: 1.2em;
  width: 2px;
}

.caret_bubble_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.caret_bubble {
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.remote_selection {
  position: absolute;
  pointer-events: none;
}
