// flex boxes
@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-col-jfs-acenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-col-jfe-acenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-col-jfs-afs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-col-jse-acenter {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@mixin flex-col-jsb-acenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-row {
  display: flex;
}

@mixin flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-jcenter-astretch {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

@mixin flex-row-jfs-acenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-row-jfs-afe {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

@mixin flex-row-jfe-acenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-row-jsa-acenter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-row-jsb-acenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-row-jcenter-afe {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

// transitions
@mixin transition-standard {
  transition: 0.1s ease all;
}

@mixin transition-long {
  transition: 0.2s ease all;
}
