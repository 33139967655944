$light_grey: #f1f1f1;
$medium_grey: #d1d1d1;
$dark_grey: #c3c3c3;
$darker_grey: #a1a1a1;
$off_black: #1e1e1e;

$primary_hue: 203;
$primary: hsl($primary_hue, 77%, 60%);
$primary_dark: hsl($primary_hue, 77%, 45%);
$primary_very_dark: hsl($primary_hue, 77%, 15%);
$primary_light: hsl($primary_hue, 77%, 80%);

$secondary_hue: 251;
$secondary: hsl($secondary_hue, 69%, 34%);
$secondary_light: hsl($secondary_hue, 69%, 50%);

$tertiary_hue: 1;
$tertiary: hsl($tertiary_hue, 82%, 66%);
$tertiary_dark: hsl($tertiary_hue, 82%, 56%);

$warning_hue: 5;
$warning: hsl($warning_hue, 79%, 30%);
